import React from 'react'
import './gardenofpeace.css'
import HomeSlider from './HomeSlider';

export const Gallery = () => {
  return (
    <div>
<div class="row">
  <div class="col-lg-4 col-md-12 mb-4 mb-lg-0">
    <img
      src="images/HomeSlider_1.jpeg"
      class="w-100 shadow-1-strong rounded mb-4"
      alt="Boat on Calm Water"
    />

    <img
      src="images/HomeSlider_2.jpeg"
      class="w-100 shadow-1-strong rounded mb-4"
      alt="Wintry Mountain Landscape"
    />
  </div>

  <div class="col-lg-4 mb-4 mb-lg-0">
    <img
      src="images/HomeSlider_3.jpeg"
      class="w-100 shadow-1-strong rounded mb-4"
      alt="Mountains in the Clouds"
    />

    <img
      src="images/HomeSlider_4.jpg"
      class="w-100 shadow-1-strong rounded mb-4"
      alt="Boat on Calm Water"
    />
  </div>

  <div class="col-lg-4 mb-4 mb-lg-0">
    <img
      src="images/HomeSlider_5.jpg"
      class="w-100 shadow-1-strong rounded mb-4"
      alt="Waves at Sea"
    />

    <img
      src="images/HomeSlider_6.jpg"
      class="w-100 shadow-1-strong rounded mb-4"
      alt="Yosemite National Park"
    />
  </div>

  <div class="col-lg-4 mb-4 mb-lg-0">
    <img
      src="images/HomeSlider_7.jpg"
      class="w-100 shadow-1-strong rounded mb-4"
      alt="Waves at Sea"
    />

    <img
      src="images/HomeSlider_8.jpg"
      class="w-100 shadow-1-strong rounded mb-4"
      alt="Yosemite National Park"
    />
  </div>

  <div className="col-lg-4 col-md-12 mb-4 mb-lg-0">
    <img
      src="images/HomeSlider_9.jpg"
      className="w-100 shadow-1-strong rounded mb-4"
      alt="Placeholder Image 9"
    />

    <img
      src="images/HomeSlider_10.jpg"
      className="w-100 shadow-1-strong rounded mb-4"
      alt="Placeholder Image 10"
    />
  </div>

  <div className="col-lg-4 mb-4 mb-lg-0">
    <img
      src="images/HomeSlider11.jpg"
      className="w-100 shadow-1-strong rounded mb-4"
      alt="Placeholder Image 11"
    />

    <img
      src="images/HomeSlider12.jpg"
      className="w-100 shadow-1-strong rounded mb-4"
      alt="Placeholder Image 12"
    />
  </div>
</div>
    </div>
  )
}
