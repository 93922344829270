import React from 'react'

export const BurialPlot = () => {
  return (
    <div className='pledgeForm'>
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdlV6u4lgfYjNRdYN8ehwNiO1oM1u0ksTde4ZVmAuw7XE2CPg/viewform?embedded=true" width="640" height="1989" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
    </div>
  )
}


