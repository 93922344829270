import React from 'react';

export const Mission = () => {
  return (
    <div className="mission-wrapper"> 
      <h1 className="mission-title">SERVICES</h1>
      <p className="mission-description">At Garden of Peace, our aim is to create a sacred haven for the Muslim community in Northern California, where we provide compassionate and unwavering support during times of grief and loss. We are dedicated to establishing a licensed Funeral and Burial facility that upholds the principles of the Qur'an and Sunnah without bias or discrimination. Our services, rooted in Islamic tradition, include:</p>
      <ol className="mission-list" role="list">
        <li className="mission-list-item">
        <b>TRANSPORTATION AND CARE:</b>  Safely transporting the deceased to our facility and ensuring their dignified care.
        </li>
        <li className="mission-list-item">
        <b>DOCUMENTATION:</b> Securing the necessary death certificates and burial permits, eliminating bureaucratic hassles. 
        </li>
        <li className="mission-list-item">
        <b>PURIFICATION:</b> Performing the sacred ritual of body washing in accordance with Islamic rites.
        </li>
        <li className="mission-list-item">
        <b>RESPECTFUL SHROUDING:</b> Enveloping the departed in kafan (shroud) and providing a simple yet respectful cardboard casket.
        </li>
        <li className="mission-list-item">
        <b>CONGREGATIONAL PRAYER:</b> Arranging congregational funeral prayers, fostering unity in times of mourning.
        </li>
        <li className="mission-list-item">
        <b>ETERNAL REST:</b> Maintaining the final resting place indefinitely, ensuring the sacredness of the grave.
        </li>
        <li className="mission-list-item">
        <b>BURIAL ARRANGEMENTS:</b> Facilitating the burial process in accordance with Islamic tradition.
        </li>
      </ol>
      <p className="mission-details">With deep gratitude for the grace of Almighty Allah (SWT) and generous support from the community we accomplished the following goals:</p>
      <li>Acquired 160 acres Land in Santa Nella, CA</li>
      <li>⁠Obtained Land Use Permit</li>
      <li>Secured Business License</li>
      <p className="mission-details">Now we are working the Infrastructure (Access Road, Gate/Fence, Parking Space, Landscaping).</p>
      <p className="mission-details">Our core commitment is to offer accessible and sustainable funeral and burial services, ensuring that every Muslim in Northern California can experience the solace of observing Islamic practices, while adhering to local regulations. In unity and devotion, we aim to be a source of comfort and support for our community during their most challenging moments.</p>
    </div>
  );
}
