import 'bootstrap/dist/css/bootstrap.min.css';
import './gardenofpeace.css';
import NavBar from './NavBar';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React, { useState, useEffect } from 'react';
import PopUp from './PopUp';

const App = () => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    // Show the modal when the component mounts
    setShowModal(true);
  }, []);

  const handleClose = () => {
    // Hide the modal
    setShowModal(false);
  };

  return (
    <div className="App">
      <NavBar />
      <ToastContainer />
      <PopUp show={showModal} onClose={handleClose} />
      {/* Rest of your app */}
      {/* Make sure to include your Routes here if you have other pages */}
      {/* <Footer /> Uncomment this if you have a Footer component */}
    </div>
  );
};

export default App;
