import React, { Component } from 'react';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import './gardenofpeace.css';
import "bootstrap/dist/css/bootstrap.min.css";

import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { AboutUs } from './AboutUs';
import { Mission } from './Mission';
import { BoardOfDirectors } from './BoardOfDirectors';
import { BurialEtiquette } from './BurialEtiquette';
import { JanazahProcess } from './JanazahProcess';
import { ContactUs } from './ContactUs';
import { WhyThisProject } from './WhyThisProject';
import { Gallery } from './Gallery';
import { FuneralServices } from './FuneralServices';
import { BurialRequest } from './BurialRequest';
import { DuaAndPrayers } from './DuaAndPrayers';
import { Donate } from './Donate';
import { Home } from './Home';
import { DonateZelle } from './DonateZelle';
import { BurialPlot } from './BurialPlot';
import { Presentation } from './Presentation';
import { QNA } from './QNA';
import { FundraisingInfo } from './FundraisingInfo';
import { Goals } from './Goals'
import { Event } from './Event';
import { Training } from './Training';
import { Volunteering } from './Volunteering';



export default class NavbarComponent extends Component {
  render() {
    return (
      <BrowserRouter>
        <div>
          <Navbar collapseOnSelect expand="lg">
            <Container>
            <div className="d-flex align-items-center">
              <Navbar.Brand as={Link} to={"/home"} style={{ fontSize: 26, color: 'black', fontWeight: 600 }}>
                <img className="logo" id="logo" src="images/logo2.png" alt="gardenofpeacelogo" width="70" height="65" style={{ verticalAlign: 'middle' }}></img>GARDEN OF PEACE
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav">
                <img src="images/hamburger.png" alt="toggle navigation"/>
              </Navbar.Toggle>

            </div>
              <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                <Nav className="ml-auto">
                  <Nav.Link as={Link} to={"/home"}>Home</Nav.Link>
                  <NavDropdown title="About Us" id="collasible-nav-dropdown">
                    <NavDropdown.Item as={Link} to={"/aboutus"} style={{ fontSize: 18 }}>About Garden of Peace </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to={"/whythisproject"} style={{ fontSize: 18 }}>Why this Project?</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to={"/gallery"} style={{ fontSize: 18 }}>Gallery</NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link as={Link} to={"/services"} >Services</Nav.Link>
                  <Nav.Link as={Link} to={"/QNA"}>Q&A</Nav.Link>
                  <NavDropdown title="Opportunitites" id="collasible-nav-dropdown">
                    <NavDropdown.Item as={Link} to={"/training"} style={{ fontSize: 18 }}>Training & Education </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to={"/volunteering"} style={{ fontSize: 18 }}>Volunteering</NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link as={Link} to={"/boardofdirectors"}>Team</Nav.Link>
                  <Nav.Link as={Link} to={"/contactus"}>Contact Us</Nav.Link>
                  <Nav.Link as={Link} to={"/burialplotpledgeform"}>Burial Plot Pledge Form</Nav.Link>
                  <Nav.Link as={Link} title="Donate" className="rounded-button" to={"/donate"}>Donate</Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          <div>
            <Routes>
                  <Route path='/' element={<Home/>}/>
                  <Route path='/home' element={<Home/>}/>
                  <Route path='/services' element={<Mission/>}/>
                  <Route path='/boardofdirectors' element={<BoardOfDirectors/>}/>
                  <Route path='/burialetiquette' element={<BurialEtiquette/>}/>
                  <Route path='/janazahprocess' element={<JanazahProcess/>}/>
                  <Route path='/aboutus' element={<AboutUs/>}/>
                  <Route path='/whythisproject' element={<WhyThisProject/>}/>
                  <Route path='/QNA' element={<QNA/>}/>
                  <Route path='/gallery' element={<Gallery/>}/>
                  <Route path='/progressgoals2023' element={<Goals/>}/>
                  <Route path='/funraising2023presentation' element={<FundraisingInfo/>}/>
                  <Route path='/duaandprayers' element={<DuaAndPrayers/>}/>
                  <Route path='/contactUs' element={<ContactUs/>}/>
                  <Route path='/funeralservices' element={<FuneralServices/>}/>
                  <Route path='/burialrequest' element={<BurialRequest/>}/>
                  <Route path='/donate' element={<Donate/>}/>
                  <Route path='/donatezellepaypal' element={<DonateZelle/>}/>
                  <Route path='/training' element={<Training/>}/>
                  <Route path='/volunteering' element={<Volunteering/>}/>
                  <Route path='/burialplotpledgeform' element={<BurialPlot/>}/>
            </Routes>
          </div>
        </div>
      </BrowserRouter>
    );
  }
}
