import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import './gardenofpeace.css';

export const AboutUs = () => {
  const [isResponsive, setIsResponsive] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsResponsive(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    // Initial call and event listener
    handleResize();
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const splitLayoutClassName = isResponsive ? 'split-layout responsive-layout' : 'split-layout';

  return (
    <div className={splitLayoutClassName}>
      <div className="image-section">
        <div className="gradient-overlay-about"></div>
        <img
          src="images/HomeSlider_10.jpg"
          alt="About Us"
          className="image-about"
        />
        <div className="image-overlay">
          <h1>ABOUT GARDEN OF PEACE</h1>
        </div>
      </div>
      <div className="about-us-section">
        <p>
          Garden of Peace is a 501c non-profit organization providing Burial Services and optionally, funeral services for all Muslims in Northern California.
          Our current Muslim cemetery has limited burial plots left as they have been serving the greater bay area Muslim communities.
        </p>
        <p>
          With the significant increase in the Muslim population in the greater bay area over the last 10 years, it is essential to maintain, preserve, and sustain the cemetery for the foreseeable future.
          The cemetery will be governed by both Islamic practices and local laws, ensuring that it remains a sacred "Garden of Peace" for all Muslims, irrespective of age, race, or gender.
        </p>
        <p><b>The property address is 17205 S Jasper Sears Road, Santa Nella, CA 95322</b></p>
        <p>
          We extend a warm invitation to become a volunteer for this meaningful cause and contribute in any way you can.
          Your support will help us uphold our legacy and create a lasting sanctuary for our community.
        </p>
        <div className="about-donate">
          <Link to="/donatecardpaypal" className="home-button"> {/* Use the Link component */}
            DONATE <FontAwesomeIcon icon={faArrowRight} />
          </Link>
        </div>
      </div>
    </div>
  );
};
