import React, { useState } from 'react';

export const QNA = () => {
  //Initalize all accordian items to open 
  const [openItems, setOpenItems] = useState(Array.from({ length: 18 }, (_, i) => i + 1));

  // Function to toggle the accordion item
  const toggleItem = (item) => {
    setOpenItems(prevItems => 
      prevItems.includes(item) ? prevItems.filter(i => i !== item) : [...prevItems, item]
    );
  };

  // Define questions and answers as an array of objects
  const questionsAndAnswers = [
    { question: "1. What is the purpose behind Garden of Peace?", answer: "It is an initiative to establish an affordable cemetery for all Muslims." },
    { question: "2. Is Garden of Peace affiliated with MCA?", answer: "No, it operates independently and serves Muslims from all mosques (masajids)." },
    { question: "3. Who is involved in this effort?", answer: "A group of dedicated volunteers have come together to form an ad-hoc team. Their mission is to establish a non-profit organization, raise funds, and acquire land for the cemetery." },
    { question: "4. What is the current progress of the project?", answer: "With deep gratitude for the grace of Almighty Allah (SWT) and the generous support from our community, we have accomplished significant milestones in our project. We successfully acquired 160 acres of land in Santa Nella, California, obtained the necessary Land Use Permit, and secured a Business License. Currently, we are focusing on developing the infrastructure, which includes constructing access roads, gates/fences, parking spaces, and landscaping to further enhance the usability and accessibility of the land." },
    { question: "5. What are the future plans for the cemetery?", answer: "The organization plans to build a small prayer hall with restrooms and washing facilities to offer funeral services in addition to burial services, all at an affordable cost." },
    { question: "6. What are the sources of funding for this initiative?", answer: "Funding comes from generous donations, interest-free personal loans, and the pre-sale of 500 burial plots."},
    { question: "7. How can individuals make donations?", answer: "Donations can be made through the organization's website (www.gardenofpeaceus.org) or by sending a personal check."},
    { question: "8. How much will the burial services cost?", answer: "The organization intends to charge no more than $3,000 for burial services, which includes $1,000 for the burial plot and $2,000 for the associated services."},
    { question: "9. What about funeral services?", answer: "Initially, funeral services will be subcontracted, with costs not exceeding $2,000. Eventually, the organization plans to provide these services in-house."},
    { question: "10. Are there any limitations on the purchase of burial plots?", answer: "Individuals may purchase up to 20 burial plots, with the condition that they do not resell any of these plots."},
    { question: "11. Why establish another cemetery?", answer: "The existing Muslim cemetery, Five Pillars, is nearing capacity. Another cemetery in San Martin will be available but with limited capacity (only 30 burials per year). Additionally, other cemeteries charge significantly higher fees, often exceeding $10,000 per burial."},
    { question: "12. Why is the cemetery located so far from certain areas?", answer: "The lack of available affordable land closer to densely populated areas necessitated this location choice. Interestingly, it is only 1.5 hours from MCA and 0.5 hour from San Jose, similar driving distance to Five Pillars."},
    { question: "13. Why should one consider donating to this cause?", answer: "Donating to this initiative is considered sadka-e-zaria (one of only three acts that continue to benefit after death), even if you do not personally utilize the cemetery services."},
    { question: "14. Is it permissible to sell burial plots?", answer: "Yes, both legally and from an Islamic perspective, it is permissible to sell burial plots."},
    { question: "15. How can individuals contribute and support this initiative?", answer: "You can help by making dua (prayers), making donations, purchasing burial plots, and volunteering your time and efforts."},
    { question: "16. Is donation money tax deductible?", answer: "Yes, for anyone who donates to Garden of Peace, we are pleased to confirm that we will provide a tax receipt at the beginning of the year." },
    { question: "17. Is the Plot donation tax deductible?", answer: "Absolutely, for individuals donating Plots to Garden of Peace, we ensure the issuance of a tax receipt at the beginning of the year." },
    { question: "18. Will those pledging plots receive any certificates?", answer: "Yes, for all individuals pledging plots, we will provide certificates along with receipts, insha’Allah." }
  ];

  return (
    <div className='qna' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <h1>FREQUENTLY ASKED QUESTIONS</h1>
      <div className="accordion" id="accordionExample">
        {questionsAndAnswers.map((qa, index) => (
          <div className="accordion-item" key={index}>
            <h2 className="accordion-header">
              <button
                className={`accordion-button ${!openItems.includes(index + 1) ? 'collapsed' : ''}`}
                type="button"
                onClick={() => toggleItem(index + 1)}
              >
                {qa.question}
              </button>
            </h2>
            <div id={`collapse${index + 1}`} className={`accordion-collapse collapse ${openItems.includes(index + 1) ? 'show' : ''}`}>
              <div className="accordion-body">
                {qa.answer}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
