import React from 'react';
import { Link } from 'react-router-dom';

// Card Component
const Card = ({ title, content, buttonText, imageUrl, link }) => {
  const cardStyle = {
    display: 'flex',
    flexDirection: 'column',
    width: '300px', // Set fixed width or use percentages
    margin: '10px 40px', // Increase the second value for left and right margins
    borderRadius: '20px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.4)',
    overflow: 'hidden',
  };  

  const imageStyle = {
    width: '100%',
    height: '300px', // Adjust as needed
    objectFit: 'cover',
  };

  const contentStyle = {
    padding: '20px',
    backgroundColor: 'white',
    textAlign: 'center',
    fontSize: '20px', // Adjust color as needed
  };

  const buttonStyle = {
    padding: '15px 15px',
    margin: '20px',
    backgroundColor: '#69b32e', // Button color
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    border: 'none',
    borderRadius: '15px',
    cursor: 'pointer',
    textDecoration: 'none', // Remove underline from links
    display: 'inline-block', // Required for links to behave like buttons
    width: 'calc(100% - 40px)', // Full width minus margin
  };

  // Define the hover style
  const buttonHoverStyle = {
    ...buttonStyle,
    backgroundColor: 'white', // New background color on hover
    color: '#69b32e', // New text color on hover
  };

  return (
    <div style={cardStyle}>
      <img style={imageStyle} src={imageUrl} alt="Card cap" />
      <div style={contentStyle}>
        <h4>{title}</h4>
        <p>{content}</p>
        {/* Use Link instead of button for navigation */}
        <Link to={link} style={buttonStyle} className="card-button">
          {buttonText}
        </Link>
      </div>
    </div>
  );
};

// Usage of Card Component
const App = () => {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'space-around', // Adjusts spacing around items
      flexWrap: 'wrap' // Allows cards to wrap on smaller screens
    }}>
      <Card
        title="THE ISSUE"
        content="Amidst the pressing challenge of limited Muslim cemetery space, Garden of Peace provides the solution. As the need for Islamic burial grounds grows, we have taken the responsibility to provide a lasting solution for all Northern Californian Muslims."
        buttonText="WHY THIS PROJECT?"
        imageUrl="images\HomeSlider_1.jpeg" // Replace with your image path
        link="/whythisproject"
      />
      <Card
        title="SERVICES WE OFFER"
        content="Garden of Peace ensures dignified Islamic funeral services by offering a range of services. We support families with transportation, purification, and prayer arrangements, honoring Islamic traditions through the bereavement process."
        buttonText="SERVICES"
        imageUrl="images/HomeSlider_9.jpg" // Replace with your image path
        link="/services"
      />
      <Card
        title="CURRENT PROGRESS"
        content="Garden of Peace's current progress is promising as they have acquired 160 acres of land in Santa Nella. An environmental report is now underway, and upon approval, a public hearing will follow to designate the land as a cemetery."
        buttonText="PROGRESS & STATUS"
        imageUrl="images/HomeSlider_13.jpg" // Replace with your image path
        link="/progressgoals2023"
      />
    </div>
  );
};

export default App;
