import React, { useEffect } from 'react';
import { DonateZelle } from './DonateZelle';

export const Donate = () => {
  
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://donorbox.org/widget.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);
  
  const demos = {
    donorbox: '<iframe src="https://donorbox.org/embed/garden-of-peace-donation" name="donorbox" allowpaymentrequest="allowpaymentrequest" seamless="seamless" frameborder="0" scrolling="no" height="1500px" width="100%" style="max-width: 500px; min-width: 250px; max-height:none!important"></iframe>'
  };
  
  function Iframe(props) {
    return (
      <div
        dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
      />
    );
  }

  return (
    <div className="donate-page-container">
      <h1>Donate to Garden of Peace</h1>
  
      <div className="donation-methods-container">
        <div className="donorbox-container">
          <Iframe iframe={demos["donorbox"]} />
        </div>
  
        <div className="zelle-container">
          <DonateZelle />
        </div>
      </div>
    </div>
  );
}
