import React from 'react';
import './gardenofpeace.css';

export const Goals = () => {
  return (
    <div className='goals'>
        <style>{'body { background-color: #96ab98; }'}</style>
        <img className='FundraisingPresentation5'
          src="images/UpdatedProgressDec.png"
          alt="Garden of Peace Progress and Goals"
        />
    </div>
  )
}
